(function ($) {
    $(document).ready(function () {

        var $bod = $('body');

        // prevent other characters in quantity field
        $bod.on('keypress', '.ecommerce-qty-field', function (e) {
            var a = [];
            var k = e.which;
            for ( var i = 48; i < 58; i++ ) {
                a.push(i);
            }
            if ($.inArray(k, a) < 0) {
                e.preventDefault();
            }
        });

        // add item form
        $bod.on('submit', '.ecommerce-add-item', function (e) {
            e.preventDefault();

            var $self = $(this),
                $cart_sidebar_update = $('#cart_sidebar_update'),
                $header_cart_items = $('#header-cart-items'),
                $banner = $('header.banner'),
                $cart_detailed = $('#cart_sidebar_update_detailed');

            if (!$cart_sidebar_update.hasClass('loading')) {
                $cart_sidebar_update.addClass('loading');
                $.post(ecommerce_strings.ajax_url, $self.serialize(), function (response) {
                    if (response.status === 'success') {

                        var id_prod = $self.find('[name=product_id]').val(),
                            hh = $banner.outerHeight(),
                            image = $self.closest('.ecommerce-product').find('#transition-image-' + id_prod);

                        if (image.length !== 0) {
                            console.log(image);

                            var image_cloned = image.clone(),
                                top = image.offset().top,
                                left = image.offset().left,
                                width = image.outerWidth(),
                                height = image.outerHeight();

                            image_cloned.css({
                                position: 'absolute',
                                top: top - (!$bod.hasClass('single-product') ? hh : 0),
                                left: left,
                                width: width,
                                height: height,
                                zIndex: 20000
                            });

                            image_cloned.appendTo($bod);

                            image_cloned.animate({
                                top: 0,
                                left: '60%',
                                width: 0,
                                height: 0
                            }, 1000, function () {
                                image_cloned.remove();
                            });
                        }

                        $cart_sidebar_update.html(response.cart);
                        $header_cart_items.text(response.items);

                        if ($cart_detailed) {
                            $cart_detailed.html(response.cart_detailed);
                        }
                    }
                    $cart_sidebar_update.removeClass('loading');
                }, 'json');
            }
        });

        $bod.on('click', '.ecommerce-categories-list-toggle', function (e) {
            e.preventDefault();
            $(this).closest('.ecommerce-categories').toggleClass('ecommerce-categories-list-opened');
        });

        // update quantity
        $bod.on('submit', '.ecommerce-update-item-qty, .ecommerce-update-item-qty-adv', function (e) {
            e.preventDefault();
            var $cart_content = $('#cart_content'),
                $self = $(this),
                $header_cart_items = $('#header-cart-items'),
                $cart_detailed = $('#cart_sidebar_update_detailed');

            if (!$cart_content.hasClass('loading')) {
                $cart_content.addClass('loading');
                $.post(ecommerce_strings.ajax_url, $self.serialize(), function (response) {
                    if (response.status === 'success') {
                        // update cart content
                        $cart_content.html(response.cart);
                        $header_cart_items.text(response.items);

                        if ($cart_detailed) {
                            $cart_detailed.html(response.cart_detailed);
                        }
                    }
                    $cart_content.removeClass('loading');
                }, 'json');
            }
        });
        $bod.on('click', '.ecommerce-btn-qty-less', function (e) {
            e.preventDefault();
            var $self = $(this),
                $form = $self.parent().parent(),
                $qty = $form.find('input[name=qty]');

            var val = parseInt($qty.val());

            if (val > 1) {
                $qty.val(val - 1);
                $form.submit();
            }
        });
        $bod.on('click', '.ecommerce-btn-qty-more', function (e) {
            e.preventDefault();
            var $self = $(this),
                $form = $self.parent().parent(),
                $qty = $form.find('input[name=qty]');

            var val = parseInt($qty.val());

            $qty.val(val + 1);
            $form.submit();
        });

        // update shipping method
        $bod.on('click', '.ecommerce-update-shipping', function () {
            var $cart_content = $('#cart_content'),
                $self = $(this);
            if (!$cart_content.hasClass('loading')) {
                $cart_content.addClass('loading');
                $.post(ecommerce_strings.ajax_url, $self.closest('form').serialize(), function (response) {
                    if (response.status === 'success') {
                        // update cart content
                        $cart_content.html(response.cart);
                    }
                    $cart_content.removeClass('loading');
                }, 'json');
            }
        });

        // update shipping method
        $bod.on('click', '.ecommerce-update-payment', function () {
            var $cart_content = $('#cart_content'),
                $self = $(this);
            if (!$cart_content.hasClass('loading')) {
                $cart_content.addClass('loading');
                $.post(ecommerce_strings.ajax_url, $self.closest('form').serialize(), function (response) {
                    if (response.status === 'success') {
                      // update cart content
                      $cart_content.html(response.cart);
                    }
                    $cart_content.removeClass('loading');
                }, 'json');
            }
        });

        // update user
        $bod.on('submit', '.ecommerce-order-confirm', function (e) {
            e.preventDefault();
            var $cart_content = $('#cart_content'),
                $self = $(this),
                $alert_container = $self.find('.alert-container');
            if (!$cart_content.hasClass('loading')) {
                $cart_content.addClass('loading');
                $.post(ecommerce_strings.ajax_url, $self.serialize(), function (response) {
                    if (response.status === 'success') {
                        window.location.replace(response.order_url);
                    } else {
                        $alert_container.html(
                            $('<div>').addClass('ecommerce-alert ecommerce-alert-' + response.status).html(response.message)
                        );
                        $cart_content.removeClass('loading');
                    }
                }, 'json');
            }
        });

        // login user
        $bod.on('submit', '.ecommerce-login', function (e) {
            e.preventDefault();
            var $cart_content = $('#cart_content'),
                $self = $(this),
                $alert_container = $self.find('.alert-container');
            if (!$cart_content.hasClass('loading')) {
                $cart_content.addClass('loading');
                $.post(ecommerce_strings.ajax_url, $self.serialize(), function (response) {
                    if (response.status === 'success') {
                        location.reload();
                    } else {
                        $alert_container.html(
                            $('<div>').addClass('ecommerce-alert ecommerce-alert-' + response.status).html(response.message)
                        );
                        $cart_content.removeClass('loading');
                    }
                }, 'json');
            }
        });

        // login user into account
        $bod.on('submit', '.ecommerce-account-login', function (e) {
            e.preventDefault();
            var $self = $(this),
                $alert_container = $self.find('.alert-container');
            if (!$self.hasClass('loading')) {
                $self.addClass('loading');
                $.post(ecommerce_strings.ajax_url, $self.serialize(), function (response) {
                    if (response.status === 'success') {
                        location.reload();
                    } else {
                        $alert_container.html(
                            $('<div>').addClass('ecommerce-alert ecommerce-alert-' + response.status).html(response.message)
                        );
                        $self.removeClass('loading');
                    }
                }, 'json');
            }
        });

        // user account address update
        $bod.on('submit', '.ecommerce-address-update', function (e) {
            e.preventDefault();
            var $self = $(this),
                $alert_container = $self.find('.alert-container');
            if (!$self.hasClass('loading')) {
                $self.addClass('loading');
                $.post(ecommerce_strings.ajax_url, $self.serialize(), function (response) {
                    $alert_container.html(
                        $('<div>').addClass('ecommerce-alert ecommerce-alert-' + response.status).html(response.message)
                    );
                    $self.removeClass('loading');
                }, 'json');
            }
        });

        // register user
        $bod.on('submit', '.ecommerce-register', function (e) {
            e.preventDefault();
            var $cart_content = $('#cart_content'),
                $self = $(this),
                $alert_container = $self.find('.alert-container');
            if (!$cart_content.hasClass('loading')) {
                $cart_content.addClass('loading');
                $.post(ecommerce_strings.ajax_url, $self.serialize(), function (response) {
                    if (response.status === 'success') {
                        location.reload();
                    } else {
                        $alert_container.html(
                            $('<div>').addClass('ecommerce-alert ecommerce-alert-' + response.status).html(response.message)
                        );
                        $cart_content.removeClass('loading');
                    }
                }, 'json');
            }
        });

    });
})(jQuery);
